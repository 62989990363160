import { Link as GLink } from 'gatsby'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ButtonSize, OutlinedButton } from 'streamr-ui'
import { Desktop, Tablet } from 'streamr-ui/css/consts'
import styled from 'styled-components'
import Topic from '~shared/Topic'
import Anyone from './logos/anyone.svg'
import Arkreen from './logos/arkreen.svg'
import Arweave from './logos/arweave.svg'
import Binance from './logos/binance.svg'
import Chirp from './logos/chirp.svg'
import Crankk from './logos/crankk.png'
import Dimo from './logos/dimo.svg'
import DropWireless from './logos/drop_wireless.png'
import Flux from './logos/flux.svg'
import Future3Campus from './logos/future3_campus.svg'
import Hotspotty from './logos/hotspotty.svg'
import Iotex from './logos/iotex.svg'
import Kwil from './logos/kwil.svg'
import Kyve from './logos/kyve.svg'
import Lit from './logos/lit.svg'
import Logstore from './logos/logstore.svg'
import Mapmetrics from './logos/mapmetrics.svg'
import Minima from './logos/minima.svg'
import Nodle from './logos/nodle.svg'
import Opsec from './logos/opsec.png'
import Polygon from './logos/polygon.svg'
import Powerpod from './logos/powerpod.svg'
import Redstone from './logos/redstone.svg'
import Subcast from './logos/subcast.svg'
import Swash from './logos/swash.svg'
import Usherlabs from './logos/usherlabs.svg'
import Wicrypt from './logos/wicrypt.svg'
import urls from '~utils/urls'

const Slide = styled.a`
    --tx: 0;

    display: flex;
    align-items: center;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(var(--tx));

    img {
        display: block;
        transform: scale(1);
        transition: 2s transform ease-in-out;
    }

    &:hover img {
        transform: scale(1.05);
        transition-duration: 0.5s;
    }
`

const DeckRoot = styled.div`
    position: relative;
    height: ${({ $height: height }) => `${height}px`};

    > a {
        height: ${({ $height: height }) => `${height}px`};
    }
`

const Viewport = styled.div`
    overflow: hidden;

    & + & {
        margin-top: 32px;
    }

    @media (min-width: ${Tablet}px) {
        & + & {
            margin-top: 48px;
        }
    }
`

const Buttons = styled.div`
    color: white;
    text-align: center;
    padding: 3rem 0 1rem;

    @media (min-width: ${Desktop}px) {
        padding: 4rem 0 1rem;
    }
`

function useAnimationFrameEffect(fps = 60, fn) {
    const fnRef = useRef(fn)

    if (fnRef.current !== fn) {
        fnRef.current = fn
    }

    useEffect(
        function dealWithAnimationFrames() {
            let mounted = true

            let frameRequestId

            let startedAt

            let lastFrameAt

            function onTick(time) {
                if (!mounted) {
                    return
                }

                if (startedAt == null) {
                    startedAt = time
                }

                if (lastFrameAt == null) {
                    lastFrameAt = -1
                }

                const elapsed = time - lastFrameAt

                if (time - lastFrameAt > 1000 / fps) {
                    lastFrameAt = time

                    if (typeof fnRef.current === 'function') {
                        fnRef.current(time, elapsed)
                    }
                }

                frameRequestId = window.requestAnimationFrame(onTick)
            }

            frameRequestId = window.requestAnimationFrame(onTick)

            return () => {
                mounted = false

                if (frameRequestId != null) {
                    window.cancelAnimationFrame(frameRequestId)

                    frameRequestId = undefined
                }
            }
        },
        [fps],
    )
}

function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2
}

function Deck({ fps = 60, speed = 10, gap = 32, rev = false, items: itemsProp, scale = 1 }) {
    const viewportRef = useRef(null)

    const [viewportWidth, setViewportWidth] = useState(0)

    const deckRef = useRef(null)

    const { maxWidth, maxHeight } = useMemo(() => {
        let maxWidth = 0

        let maxHeight = 0

        for (const { width, height, targetWidth = width } of itemsProp) {
            const finalWidth = targetWidth * scale

            if (maxWidth < finalWidth) {
                maxWidth = finalWidth
            }

            const targetHeight = (height * finalWidth) / width

            if (maxHeight < targetHeight) {
                maxHeight = targetHeight
            }
        }

        return { maxWidth, maxHeight }
    }, [itemsProp, scale])

    const items = useMemo(() => {
        const result = []

        let x = 0

        while (x < viewportWidth + 2 * maxWidth) {
            for (const { width, height, targetWidth = width, ...rest } of itemsProp) {
                const finalWidth = targetWidth * scale

                result.push({
                    ...rest,
                    index: result.length,
                    x,
                    width: finalWidth,
                    height: (height * finalWidth) / width,
                })

                x += finalWidth + gap
            }
        }

        return result
    }, [itemsProp, viewportWidth, maxWidth, gap, scale])

    const totalWidth = useMemo(
        () => items.reduce((sum, { width }) => sum + width, items.length * gap),
        [items, gap],
    )

    const [hover, setHover] = useState([0, false])

    const speedScaleRef = useRef(1)

    const dxRef = useRef(0)

    useAnimationFrameEffect(fps, (time) => {
        const [updatedAt, fromSpeedScale, isMouseOver] = hover

        const toSpeedScale = isMouseOver ? 0.05 : 1

        const v =
            easeInOutCubic(Math.min(1, (time - updatedAt) / 1000)) * (toSpeedScale - fromSpeedScale)

        speedScaleRef.current = fromSpeedScale + v
    })

    useAnimationFrameEffect(fps, (_, elapsed) => {
        const { current: deck } = deckRef

        if (deck) {
            dxRef.current += speedScaleRef.current * speed * (elapsed / 100)

            deck.querySelectorAll('& > a').forEach((slide) => {
                const dx = rev ? -dxRef.current : dxRef.current

                slide.style.transform = `translateX(-${maxWidth}px) translateX(mod(calc(${dx}px + var(--tx)), ${totalWidth}px))`
            })
        }
    })

    useEffect(function calculateViewportWidth() {
        function onResize() {
            if (viewportRef.current) {
                setViewportWidth(viewportRef.current.clientWidth)
            }
        }

        onResize()

        window.addEventListener('resize', onResize)

        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [])

    return (
        <Viewport ref={viewportRef}>
            <DeckRoot
                ref={deckRef}
                $height={maxHeight}
                onMouseEnter={() => {
                    setHover([performance.now(), speedScaleRef.current, true])
                }}
                onMouseLeave={() => {
                    setHover([performance.now(), speedScaleRef.current, false])
                }}
            >
                {items.map(({ alt, index, x, src, width, height, url }) => (
                    <Slide
                        key={index}
                        href={url}
                        target="_blank"
                        rel="noreferrer noopener"
                        style={{
                            '--tx': `${x}px`,
                        }}
                    >
                        <img alt={alt} src={src} width={width} height={height} />
                    </Slide>
                ))}
            </DeckRoot>
        </Viewport>
    )
}

const PartnersRoot = styled.div`
    max-width: 100%;
    overflow: hidden;
`

const row0 = [
    {
        alt: 'Arkreen',
        url: 'https://x.com/streamr/status/1744696035564237277',
        src: Arkreen,
        width: 160,
        height: 32,
    },
    {
        alt: 'ANyONe',
        url: 'https://x.com/streamr/status/1781003487586762861',
        src: Anyone,
        width: 134,
        height: 36,
    },
    { alt: 'Hotspotty', url: 'https://x.com/hotspotty', src: Hotspotty, width: 160, height: 40 },
    {
        alt: 'Kyve',
        url: 'https://x.com/streamr/status/1707053196449825006',
        src: Kyve,
        width: 114,
        height: 26,
    },
    {
        alt: 'Flux',
        url: 'https://fluxofficial.medium.com/host-your-streamr-broker-node-via-the-flux-marketplace-4fcad2a2c15f',
        src: Flux,
        width: 114,
        height: 36,
    },
    {
        alt: 'Dimo',
        url: 'https://x.com/DIMO_Network/status/1503756288664248323',
        src: Dimo,
        width: 106,
        height: 28,
    },
    {
        alt: 'Polygon',
        url: 'https://polygon.technology/blog/how-real-time-data-infra-powers-depin-on-polygon-a-guide-by-streamr',
        src: Polygon,
        width: 151,
        height: 29,
    },
    {
        alt: 'Arweave',
        url: 'https://x.com/onlyarweave/status/1713920325329834054',
        src: Arweave,
        width: 175,
        height: 42,
    },
    { alt: 'Binance', url: 'https://x.com/binance', src: Binance, width: 173, height: 32 },
    {
        alt: 'Lit',
        url: 'https://blog.streamr.network/streamr-integrates-lit-protocol/',
        src: Lit,
        width: 39,
        height: 28,
    },
    {
        alt: 'Iotex',
        url: 'https://x.com/iotex_io/status/1767977852710826111',
        src: Iotex,
        width: 156,
        height: 44,
    },
    {
        alt: 'Minima',
        url: 'https://x.com/Minima_Global/status/1767197009478398245',
        src: Minima,
        width: 168,
        height: 32,
    },
    {
        alt: 'Future3 Campus',
        url: 'http://www.future3.io/',
        src: Future3Campus,
        width: 361,
        height: 124,
        targetWidth: 110,
    },
]

const row1 = [
    { alt: 'Subcast', url: 'https://subcast.video/', src: Subcast, width: 159, height: 33 },
    { alt: 'Wicrypt', url: 'https://wicrypt.com/', src: Wicrypt, width: 131, height: 34 },
    {
        alt: 'Chirp',
        url: 'https://x.com/ChirpDeWi/status/1803037410877382692',
        src: Chirp,
        width: 151,
        height: 26,
    },
    {
        alt: 'Crankk',
        url: 'https://crankk.io/',
        src: Crankk,
        width: 242,
        height: 90,
        targetWidth: 121,
    },
    {
        alt: 'Powerpod',
        url: 'https://x.com/streamr/status/1785595441758802326',
        src: Powerpod,
        width: 175,
        height: 32,
    },
    {
        alt: 'RedStone',
        url: 'https://blog.streamr.network/interview-how-redstone-and-streamr-are-changing-the-web3-oracle-landscape/',
        src: Redstone,
        width: 180,
        height: 34,
    },
    {
        alt: 'Swash',
        url: 'https://streamr.network/case-studies/swash',
        src: Swash,
        width: 144,
        height: 42,
    },
    {
        alt: 'LogStore',
        url: 'https://x.com/henripihkala/status/1684242087363981328',
        src: Logstore,
        width: 148,
        height: 56,
    },
    {
        alt: 'Nodle',
        url: 'https://x.com/NodleNetwork/status/1717542075816710575',
        src: Nodle,
        width: 132,
        height: 38,
    },
    {
        alt: 'MapMetrics',
        url: 'https://x.com/MapMetrics/status/1699076168547553762',
        src: Mapmetrics,
        width: 71,
        height: 70,
    },
    {
        alt: 'Kwil',
        url: 'https://x.com/streamr/status/1798732535180013598',
        src: Kwil,
        width: 1192,
        height: 316,
        targetWidth: 133,
    },
    {
        alt: 'UsherLabs',
        url: 'https://x.com/streamr/status/1798732535180013598',
        src: Usherlabs,
        width: 500,
        height: 86,
        targetWidth: 146,
    },
    {
        alt: 'drop wireless',
        url: 'https://x.com/streamr/status/1796191813243380135',
        src: DropWireless,
        width: 341,
        height: 106,
        targetWidth: 179,
    },
    {
        alt: 'OpSec',
        url: 'https://x.com/OpSecCloud/status/1779871782884786282',
        src: Opsec,
        width: 312,
        height: 80,
        targetWidth: 156,
    },
]

export function Partners() {
    const isTablet = useMediaQuery({ minWidth: Tablet })

    const isDesktop = useMediaQuery({ minWidth: Desktop })

    const [scale, gap] = isDesktop ? [1, 120] : isTablet ? [0.8, 80] : [0.65, 48]

    const ssr = typeof window === 'undefined'

    return (
        !ssr && (
            <PartnersRoot>
                <Deck speed={4} scale={scale} gap={gap} items={row0} />
                <Deck speed={3.7} scale={scale} gap={gap} rev items={row1} />
                <Buttons>
                    <OutlinedButton
                        tag={GLink}
                        size={ButtonSize.Capybara}
                        to={urls.project.ecosystem}
                    >
                        Explore Streamr Ecosystem
                    </OutlinedButton>
                </Buttons>
            </PartnersRoot>
        )
    )
}

export const PartnersTopic = styled(Topic)`
    background: radial-gradient(
            ellipse at 50% 60%,
            rgba(62, 79, 236, 0.2) 0%,
            rgba(62, 79, 236, 0) 60%
        ),
        radial-gradient(
            ellipse 25% 70% at 50% 80%,
            rgba(14, 165, 255, 0.2) 5%,
            rgba(14, 165, 255, 0) 100%
        ),
        #091331;
`
